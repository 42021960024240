import React from "react";
import PrivacyContainer from "../../../Components/PrivacyPolicy/PrivacyContainer";
import Button from "../../../Components/Button/Button";
import business from "../../../Assets/Images/business.png";
import { useHistory } from "react-router-dom";
function Introduction({ handleToUpdate }: { handleToUpdate: () => void }) {
  const history = useHistory();
  return (
    <div>
      <div className="container">
        <div className="header">
          <div className="logoContainer">
            <img src={business} alt="logo" />{" "}
          </div>
          <div className="titleContainerSuccess">
            <h4 className="title">
              Please provide a document that shows you are legally authorized to
              make financial decisions for the business entity.
            </h4>
          </div>
          <div className="announcementTitle" onClick={() => history.push("/")}>
            * I do not have a copy of my documents available. I will complete my
            application later.
          </div>
          <PrivacyContainer />
          <Button
            label="Continue"
            className="buttonContinue"
            onClick={handleToUpdate}
          />
        </div>
      </div>
    </div>
  );
}

export default Introduction;
