import * as React from "react";
import { useHistory } from "react-router";

import Button from "../Button/Button";

import SuccesLogo from "../../Assets/Images/check.png";
import "./SuccesScreen.css";
import { GetUserStatus } from "../../Api/onBoarding";
import { AppContext } from "../../Context/Context";

interface SuccessScreenProps {
  title: string;
  heading: string;
  navigate?: string;
  button?: string;
  label?: string;
}

const SuccessScreen = ({
  title,
  heading,
  navigate,
  button,
  label,
}: SuccessScreenProps) => {
  const history = useHistory();

  const {
    firstName,
    lastName,
    applicationStatus,
    setApplicationStatus,
    userId,
  } = React.useContext(AppContext);

  const checkFlow = React.useCallback(async () => {
    const data = await GetUserStatus.fetchMainData(userId);
    setApplicationStatus(data.message.application_status);
  }, [setApplicationStatus, userId]);

  console.log("navigate ??", navigate);

  React.useEffect(() => {
    if (navigate === "/") {
      checkFlow();
      setTimeout(() => {
        history.replace("/");
      }, 5000);
    }
  }, [checkFlow]);

  const handler = () => {
    history.replace(`/${navigate}`);
  };

  return (
    <div>
      <div className="container">
        <div className="header">
          <div className="logoContainer">
            <img src={SuccesLogo} alt="logo" />
          </div>
          <div className="successScreenContainer">
            <h2 className="succesTitle">{title} </h2>
            <h4 className="title">{heading}</h4>
          </div>
          {navigate === "/" ? null : (
            <div className="continueBtn">
              <Button
                label={label ? label : "Continue"}
                className="buttonContinue"
                onClick={handler}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuccessScreen;
