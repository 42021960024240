import * as React from "react";
import "./button.css";

interface ButtonProps {
  disabled?: boolean;
  label: string;
  icon?: JSX.Element | null;
  className: string;
  onClick: (e: any) => Promise<void> | void;
}

function Button({ label, onClick, icon, className, disabled = false }: ButtonProps) {
  console.log("label", label);

  // Define the inline styles conditionally
  const buttonStyle: React.CSSProperties =
    label === "Upload" 
      ? {} : label === "Agree & Submit my application" ? { fontSize: "20px", fontWeight: "normal" as React.CSSProperties["fontWeight"], height: "50px" }
      : { fontSize: "22px", fontWeight: "normal" as React.CSSProperties["fontWeight"], height: "50px" };

  return (
    <div className="button-cont-text">
      <button
        className={className}
        disabled={disabled}
        onClick={onClick}
        style={buttonStyle} // Apply conditional inline styles here
      >
        {icon}
        {label}
      </button>
    </div>
  );
}

export default Button;
