import React, { useState, ChangeEvent, FocusEvent } from "react";
import InputField from "../Input/InputField/InputField";
import { AppContext } from "../../Context/Context";
import {
  NON_US_INDIVIDUAL_TITLE,
  NON_US_INDIVIDUAL_HEADING,
  NON_US_HEADING,
  FOOTER_MESSAGE,
} from "../../Constant/Content";
import "./NonUsFlow.css";
import { first } from "lodash";

const NonUsIndividual = () => {
  const { firstName, setFirstName,setFirstNameErr } = React.useContext(AppContext);
  // const { email, setEmailAddress } = React.useContext(AppContext);
  const { lastName, setLastName,setLastNameErr } = React.useContext(AppContext);
  const [firstNameValue, setFirstNameValue] = useState<string>("");
  const [firstNameError, setFirstNameError] = useState<string>("");
  const [lastNameValue, setLastNameValue] = useState<string>("");
  const [lastNameError, setLastNameError] = useState<string>("");

  const handleFirstNameChange = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const firstName: string = event.target.value;
    const characterRegex: RegExp = /^[a-zA-Z\s]*$/; // Allow alphabetic characters and spaces

    // First check for character validation
    if (!characterRegex.test(firstName)) {
      setFirstNameError(
        "Input must contain only alphabetic characters and spaces."
      );
      setFirstNameErr(
        "Input must contain only alphabetic characters and spaces."
      );
    }
    // Then check for length validation
    else if (firstName.length > 15) {
      setFirstNameError("Must be 15 characters or less.");
      setFirstNameErr("Must be 15 characters or less.");
    } else {
      setFirstNameValue(firstName);
      setFirstName(firstName);
      setFirstNameError(""); // No error
      setFirstNameErr(""); // No error
    }
  };

  // Handle when first name field loses focus (onBlur)
  const handleFirstNameBlur = (event: FocusEvent<HTMLInputElement>): void => {
    // Clear error when field loses focus if the input is valid
    const characterRegex: RegExp = /^[a-zA-Z\s]*$/;
    if (characterRegex.test(firstNameValue) && firstNameValue.length <= 15) {
      setFirstNameError("");
      setFirstNameErr("");
    }
  };

  const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const lastName: string = event.target.value;
    const characterRegex: RegExp = /^[a-zA-Z\s]*$/; // Allow alphabetic characters and spaces

    // First check for character validation
    if (!characterRegex.test(lastName)) {
      setLastNameError(
        "Input must contain only alphabetic characters and spaces."
      );
      setLastNameErr(
        "Input must contain only alphabetic characters and spaces."
      );
    }
    // Then check for length validation
    else if (lastName.length > 30) {
      setLastNameError("Must be 30 characters or less.");
      setLastNameErr("Must be 30 characters or less.");
    } else {
      setLastNameValue(lastName);
      setLastName(lastName);
      setLastNameError(""); // No error
      setLastNameErr(""); // No error
    }
  };

 // Handle when last name field loses focus (onBlur)
 const handleLastNameBlur = (event: FocusEvent<HTMLInputElement>): void => {
  const characterRegex: RegExp = /^[a-zA-Z\s]*$/;
  if (characterRegex.test(lastNameValue) && lastNameValue.length <= 30) {
    setLastNameError("");
    setLastNameErr("");
  }
};

  return (
    <div>
      {" "}
      {/* <h4 className="nonUsTitle">{NON_US_INDIVIDUAL_TITLE}</h4> */}
      <h6 className="nonUsHeading">{NON_US_INDIVIDUAL_HEADING}</h6>
      <h3 className="title">{NON_US_INDIVIDUAL_TITLE} </h3>
      <InputField
        type="text"
        placeholder="First Name"
        name="firstName"
        value={firstName}
        onChange={handleFirstNameChange}
        onBlur={handleFirstNameBlur}
        className="inputField"
      />
      {firstNameError !== "" && (
        <p style={{ color: "red" }}>{firstNameError}</p>
      )}
      <InputField
        type="text"
        placeholder="Last Name"
        name="lastName"
        value={lastNameValue}
        onChange={handleLastNameChange}
        onBlur={handleLastNameBlur}
        className="inputField"
      />
      {lastNameError !== "" && <p style={{ color: "red" }}>{lastNameError}</p>}
      {/* <InputField
        type="text"
        placeholder="Email"
        name="email"
        value={email}
        onChange={(e) => setEmailAddress(e.target.value)}
        className="inputField"
      /> */}
      <div>
        <p style={{ marginTop: 10 }}>{FOOTER_MESSAGE}</p>
      </div>
    </div>
  );
};

export default NonUsIndividual;
