import Button from "../../../Components/Button/Button";
import Logo from "../../../Assets/Images/card.png";
import "./style.css";
import { useHistory } from "react-router-dom";
import PrivacyContainer from "../../../Components/PrivacyPolicy/PrivacyContainer";

const Identification = ({ handleToUpdate }: { handleToUpdate: () => void }) => {
  const history = useHistory();
  return (
    <div className="container">
      <div className="header">
        <div className="logoContainer">
          <img src={Logo} alt="logo" />
        </div>

        <div>
          <h4 className="title">Next, we need to verify your identity</h4>
        </div>
        <div
          className="announcementTitle"
          onClick={() => history.push("/")}
        >
          * I do not have a copy of my documents available. I will complete my
          application later.
        </div>
        <PrivacyContainer />
        <div className="continueBtn">
          <Button
            label="Continue"
            className="buttonContinue"
            onClick={handleToUpdate}
          />
        </div>
      </div>
    </div>
  );
};

export default Identification;
