import * as React from "react";
import MainStepper from "../../../Components/Stepper/MainStepper";
import Termsandcondition from "../../Onboarding/AddressScreens/Termsandcondition";
import SuccessScreen from "../../../Components/SuccessScreen/SuccessScreen";
import { Routes } from "../../../Constant/Routes/Routes";

const arraySteps = [{ title: "Terms and condition" }, { title: "Success" }];

const FinalizeOnboarding = () => {
  const [currentStep, setcurrentStep] = React.useState(0);

  const handleToUpdate = () => {
    setcurrentStep(currentStep + 1);
  };

  const renderComponent = () => {
    switch (currentStep) {
      case 0:
        return <Termsandcondition handleToUpdate={handleToUpdate} />;
      case 1:
        return (
          <SuccessScreen
            title="Success"
            heading="Your application has been recieved and is now under review. We will notify you when your account is ready."
            navigate={Routes.MAIN}
            // label="Done"
          />
        );
      default:
        return null;
    }
  };

  return (
    <MainStepper currentStep={currentStep} stepsArray={arraySteps}>
      {renderComponent()}
    </MainStepper>
  );
};

export default FinalizeOnboarding;
