import React, { useState } from "react";
import "./index.css";
import PassportCapture from "../../../Components/Files/Passport";
import Button from "../../../Components/Button/Button";
import File from "../../../Assets/Images/file.png";
import Failed from "../../../Assets/Images/failed.png";
import { Accreditation } from "../../../Api/onBoarding";

interface dataImages {
  fname: string;
  fimage: File | null;
}

function AccreditedVerification({
  handleToUpdate,
}: {
  handleToUpdate: () => void;
}) {
  const [frontImage, setFrontImage] = useState<File | null>(null);
  const [fileName, setFileName] = useState("");
  const [name_img, setname_img] = useState<dataImages[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null); // Error state
  const userId : string = localStorage.getItem("userId") || ''

  const retakeImage = () => {
    setFrontImage(null);
    setError(null); // Reset error when retaking the image
  };

  const handleFileName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(e.target.value);
  };

  const handleUpload = () => {
    if (frontImage) {
      if (frontImage.size > 20 * 1024 * 1024) {
        // Check if the file size exceeds 20 MB
        setError("File size exceeded. Maximum allowed size is 20 MB.");
        return;
      }

      if (name_img.length < 5) {
        const newObj = {
          fname: fileName,
          fimage: frontImage,
        };
        setname_img([...name_img, newObj]);
        setFileName("");
        setFrontImage(null);
        setError(null); // Clear any previous errors on successful upload
      } else {
        setError("You’ve reached the maximum limit of 5 documents.");
      }
    }
  };

  async function handleContinue() {
    setLoading(true);
    const data = { name_img };
    await Accreditation.post(data,userId);
    handleToUpdate();
    setLoading(false);
  }

  const handleRemove = (index: number) => {
    const newList = name_img.filter((_, i) => i !== index);
    setname_img(newList);
  };

  return (
    <div className="container">
      <div className="documentContainer">
        <div className="topContainer">
          <div className="headerWithBack">
            <h1 className="title">
              Upload one or more documents that verify accredited investor
              status
            </h1>
          </div>
          <p className="verif_text">
            Example: brokerage statement or bank statement.
          </p>

          <div className="img_container">
            <div className="btn_textbox">
              <input
                type="text"
                name=""
                value={fileName}
                placeholder="File Name / Title"
                onChange={handleFileName}
              />
              <Button
                disabled={!frontImage || !fileName}
                className="upload_btn"
                label="Upload"
                onClick={handleUpload}
              />
            </div>
            <PassportCapture
              frontImage={frontImage}
              setFrontImage={setFrontImage}
              retakeImage={retakeImage}
            />
          </div>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <div className="docs_list">
            Documents List
            <hr />
            {name_img.map((obj, index) => (
              <div key={index} className="fileSection">
                <img src={File} alt="" />
                <span className="fs-5 mb-1">{obj.fname}</span>
                <div className="remove">
                  <img
                    src={Failed}
                    onClick={() => handleRemove(index)}
                    alt=""
                  />
                </div>
              </div>
            ))}
          </div>
          <Button
            disabled={name_img.length === 0 || name_img.length > 5}
            icon={
              loading ? (
                <i className="fas fa-circle-notch fa-spin mt-2"></i>
              ) : null
            }
            label="Continue"
            className={
              name_img.length === 0 ? "disableButton" : "buttonContinue"
            }
            onClick={handleContinue}
          />
        </div>
      </div>
    </div>
  );
}

export default AccreditedVerification;
