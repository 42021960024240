import Button from "../../../Components/Button/Button";
import idcardLogo from "../../../Assets/Images/idcard.png";

import "./style.css";
import PrivacyContainer from "../../../Components/PrivacyPolicy/PrivacyContainer";
import { AppContext } from "../../../Context/Context";
import { useContext, useEffect } from "react";
import { Info } from "../../../Api/onBoarding";
import { useHistory } from "react-router-dom";
const VerifiAddressScreen = ({
  handleToUpdate,
  setcurrentStep,
}: {
  handleToUpdate: () => void;
  setcurrentStep: (number: number) => void;
}) => {
  const { investorType } = useContext(AppContext);
  const history = useHistory();

  return (
    <div className="container">
      <div className="header">
        <div className="logoContainer">
          <img src={idcardLogo} alt="logo" />{" "}
        </div>

        <div className="titleContainerSuccess">
          <h4 className="title">
            {investorType === "1" || investorType === "2"
              ? "Next, we need to verify your entity address"
              : "Next, we need to verify your address"}
          </h4>
        </div>
        <div className="announcementTitle" onClick={() => history.push("/")}>
          * I do not have a copy of my documents available. I will complete my
          application later.
        </div>
        <PrivacyContainer />
        <Button
          label="Continue"
          className="buttonContinue"
          onClick={handleToUpdate}
        />
      </div>
    </div>
  );
};

export default VerifiAddressScreen;
